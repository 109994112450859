const appConstants = {
    store: {
        routes: {
            auth: 'custom.auth',
            ui: 'custom.ui',
            transactions: 'custom.transactions',
            users: 'custom.users',
            common: 'custom.common',
            cards: 'custom.cards',
            stats: 'custom.stats',
            payments: 'custom.payments',
            appReducer: {
                employeesData: 'appReducer.employeesData',
            },
            transactionReducer: 'transactionReducer',
        },
    },
    api: {
        urls: {
            auth: {
                login: '/login',
                register: '/register',
                forgotPassword: '/forgot-password',
                changePassword: '/change-password',
                smsVerification: '/smsVerification',
            },
            common: {
                role: '/role',
                avatar: '/avatar',
                name: '/name',
                downloadFile: '/fileDownload?fileId=',
                downloadKybFile: '/downloadKybFile?fileId=',
            },
            user: {
                index: '/user',
                avatar: '/user/avatar',
                forgotPassword: {
                    index: '/user/forgotpassword',
                    sms: '/user/forgotpassword/sms',
                },
                resetPassword: '/user/resetpassword',
                list: '/user/list',
            },
            business: {
                userDetails: '/business/userdetails',
                transactions: '/business/transactions',
                transactionCategories: '/business/transactioncategories',
            },
        },
    },
    routes: {
        root: '/',
        auth: {
            login: '/_login',
            register: '/_register',
            forgotPassword: '/_forgot-password',
            changePassword: '/_change-password',
            changeMobile: '/_change-mobile',
            smsVerification: '/_smsVerification',
            smsMobileVerification: '/_smsMobileVerification',
            detailsRequired: '/_details-required',
        },
        business: {
            index: '/business',
            dashboard: '/business/dashboard',
            readytransactions: '/business/readytransactions',
            transactions: '/business/transactions',
            users: '/business/users',
            editUsers: '/business/edit-users',
            permissions: '/business/permissions',
            rejections: '/business/rejected-transactions',
            cardLimits: '/business/limitrequests',
            uploadTransferProof: '/business/uploadTransferProof',
            settings: '/business/settings',
            billings: '/business/bills',
            readyDetails: '/business/ready-details/:userId/:date',
            user: '/business/user/:userId',
            transaction: '/business/transaction/:id',
            rejectionDetails: '/business/rejection-details/:userId/:date',
            userProfile: '/business/user-profile',

        },
        payroll: {
            index: '/payroll',
            employees: '/payroll/employees',
            reviewCompanyInfo: '/payroll/reviewCompanyInfo',
            userProfile: '/payroll/user-profile',
            users: '/payroll/users',
            permissions: '/payroll/permissions',
            createIndividualDisbursement: '/payroll/createIndividualDisbursement',
            createBulkDisbursement: '/payroll/createBulkDisbursement',
            createWPSBulkDisbursement: '/payroll/createWPSBulkDisbursement',
            createExternalWPSBulkDisbursement: '/payroll/createExternalWPSBulkDisbursement',
            uploadTransferProof: '/payroll/uploadTransferProof',
            viewPayment: '/payroll/viewPayment',
            authorizeBulkPayment: '/payroll/authorizeBulkPayment',
            authorizeIndividualPayment: '/payroll/authorizeIndividualPayment',
            authorizeWpsPayment: '/payroll/authorizeWpsPayment',
            authorizeExternalWpsPayment: '/payroll/authorizeExternalWpsPayment',
            validateBulkPayment: '/payroll/validateBulkPayment',
            validateIndividualPayment: '/payroll/validateIndividualPayment',
            validateWpsPayment: '/payroll/validateWpsPayment',
            validateExternalWpsPayment: '/payroll/validateExternalWpsPayment',
            wpsStatus: '/payroll/wpsStatus',
            statusReport: '/payroll/statusReport',
            dashboard: '/payroll/dashboard',
        },
    },
    apps: {
        business: 'BUSINESS',
        payroll: 'PAYROLL',
    },
    elements: {},
    transaction: {
        statuses: {
            CREATED: 'CREATED',
            READY: 'READY',
            VALIDATED: 'VALIDATED',
            APPROVED: 'APPROVED',
            REJECTED: 'REJECTED',
        },
        transactionFor: {
            EXPENSE_CARDS_SUBSCRIPTION: 'EXPENSE_CARDS_SUBSCRIPTION',
            WPS_SALARY: 'WPS_SALARY',
            SALARY: 'SALARY',
        },
    },
    card: {
        statuses: {
            CREATED: 'CREATED',
            READY: 'READY',
            VALIDATED: 'VALIDATED',
            APPROVED: 'APPROVED',
            REJECTED: 'REJECTED',
        },
    },
    employee: {
        statuses: {
            COMPLETE: {
                id: 'COMPLETE',
                text: 'Account Active',
            },
            READY_FOR_ACTIVATION: {
                id: 'READY_FOR_ACTIVATION',
                text: 'Card ready for activation',
            },
            REGISTRATION_SUCCESSFUL: {
                id: 'REGISTRATION_SUCCESSFUL',
                text: 'Registration successful',
            },
            IBOC_USER_COMPLETED: {
                id: 'IBOC_USER_COMPLETED',
                text: 'Details submitted',
            },
            REGISTRATION_FAILED: {
                id: 'REGISTRATION_FAILED',
                text: 'Registration failed',
            },
            NEW_REGISTRATION: {
                id: 'NEW_REGISTRATION',
                text: 'Registration started',
            },
        },
        cardStatuses: {
            ACTIVATED: {
                id: 'ACTIVATED',
                text: 'Account active',
            },
            VOID: {
                id: 'VOID',
                text: 'Account voided',
            },
            CLOSED: {
                id: 'CLOSED',
                text: 'Account closed',
            },
            LINKED: {
                id: 'LINKED',
                text: 'Card linked to employee',
            },
            CREATED: {
                id: 'CREATED',
                text: 'Card created',
            },
        },
    },
    wps: {
        statuses: {
            UPLOADED: {
                id: 'UPLOADED',
                text: 'File uploaded',
            },
            PROCESSING: {
                id: 'PROCESSING',
                text: 'Processing file',
            },
            IBAN_CHECK: {
                id: 'IBAN_CHECK',
                text: 'Verifying details',
            },
            READY: {
                id: 'READY',
                text: 'Ready for approval',
            },
            APPROVED: {
                id: 'APPROVED',
                text: 'Approved',
            },
            REJECTED: {
                id: 'REJECTED',
                text: 'Rejected',
            },
            VALIDATED: {
                id: 'VALIDATED',
                text: 'File ready for approval',
            },
            SIF_SENT: {
                id: 'SIF_SENT',
                text: 'Pending processing',
            },
            SIF_SEND_FAILED: {
                id: 'SIF_SEND_FAILED',
                text: 'Try again in 10 mins',
            },
            PIF_FETCHED: {
                id: 'PIF_FETCHED',
                text: 'Processing file',
            },
            CASA_PENDING: {
                id: 'CASA_PENDING',
                text: 'Processing file',
            },
            WPS_VALIDATION_FAILED: {
                id: 'WPS_VALIDATION_FAILED',
                text: 'Incorrect details in file',
            },
            SUCCESSFUL: {
                id: 'SUCCESSFUL',
                text: 'Payment successful',
            },
            FAILED: {
                id: 'FAILED',
                text: 'Contact us for more details',
            },

        },
    },
    report: {
        statuses: {
            COMPLETED: {
                id: 'COMPLETED',
                text: 'Completed',
            },
            'PENDING VALIDATION': {
                id: 'PENDING VALIDATION',
                text: 'Pending validation',
            },
            'PENDING AUTHORIZATION': {
              id: 'PENDING AUTHORIZATION',
              text: 'Pending authorization',
            },
            REJECTED: {
                id: 'REJECTED',
                text: 'Rejected',
            },
            PROCESSING: {
                id: 'PROCESSING',
                text: 'Processing',
            },
        },
    },
    order: {
        asc: 'asc',
        desc: 'desc',
    },
    persistentValue: {
        keys: {
            RowsPerPage: 'RowsPerPage',
        },
    },
    dialogs: {
        createUser: 'createUser',
        uploadUser: 'uploadUser',
        uploadEntireList: 'uploadEntireList',
        freezeCard: 'freezeCard',
        unfreezeCard: 'unfreezeCard',
        deleteUser: 'deleteUser',
        editLimits: 'editLimits',
        rejectAll: 'rejectAll',
        rejectAllGetReason: 'rejectAllGetReason',
        rejectSelectedGetReason: 'rejectSelectedGetReason',
        rejectOneGetReason: 'rejectOneGetReason',
        approveAll: 'approveAll',
        rejectSelected: 'rejectSelected',
        approveSelected: 'approveSelected',
        rejectOne: 'rejectOne',
        approveOne: 'approveOne',
        clearOne: 'clearOne',
        clearSelected: 'clearSelected',
        submitNewThreshold: 'submitNewThreshold',
        submitNewReminders: 'submitNewReminders',
        preventOwnApprovals: 'preventOwnApprovals',
        enableOwnApprovals: 'enableOwnApprovals',
        saveCategories: 'saveCategories',
        saveCardLimits: 'saveCardLimits',
        uploadUserAvatarDialog: 'UploadUserAvatarDialog',
        changeMobile: 'changeMobile',
        validateMobileSms: 'validateMobileSms',
    },
    limits: {
        MONTHLY: 'MONTHLY',
        WEEKLY: 'WEEKLY',
        DAILY: 'DAILY',
    },
    events: {
        auth: {
            smsForRestorePassword: 'smsForRestorePassword',
        },
    },
    permissions: {
        business: {
            businessAdmin: 'businessAdmin',
            viewClearRejectedTransactions: 'viewClearRejectedTransactions',
            approveRejectBusinessLimitRequests: 'approveRejectBusinessLimitRequests',
            uploadTransferProof: 'uploadTransferProof',
            businessSubscriptionCharges: 'businessSubscriptionCharges',
            viewBusinessCards: 'viewBusinessCards',
            approveRejectBusinessTransactions: 'approveRejectBusinessTransactions',
            createUser: 'createUser',
            business: 'business',
            viewBusinessCard: 'viewBusinessCard',
            businessCardControls: 'businessCardControls',
            addUpdateBusinessCards: 'addUpdateBusinessCards',
        },
        payroll: {
            createUser: 'createUser',
            business: 'business',
            businessAdmin: 'businessAdmin',
            exportEmployeeData: 'exportEmployeeData',
            authorizeWPSBulkSalary: 'authorizeWPSBulkSalary',
            authorizeWPSCASABulkSalary: 'authorizeWPSCASABulkSalary',
            authorizeBulkSalary: 'authorizeBulkSalary',
            authorizeIndividualSalary: 'authorizeIndividualSalary',
            uploadTransferProof: 'uploadTransferProof',
            uploadSalaryFile: 'uploadSalaryFile',
            uploadWPSSalaryFile: 'uploadWPSSalaryFile',
            uploadWPSCASASalaryFile: 'uploadWPSCASASalaryFile',
            viewSalaryDetails: 'viewSalaryDetails',
            validateWPSBulkSalary: 'validateWPSBulkSalary',
            validateWPSCASABulkSalary: 'validateWPSCASABulkSalary',
            addEmployee: 'addEmployee',
            updateKybDetails: 'updateKybDetails',
            validateBulkSalary: 'validateBulkSalary',
            validateIndividualSalary: 'validateIndividualSalary',
            validatePayments: 'validatePayments',
            unlinkEmployee: 'unlinkEmployee',
        },
    },
    login: {
        status: {
            detailsRequired: 'DETAILS_REQUIRED',
            smsVerification: '2FA',
        },
    },
    messages: {
        updatedSuccessfully: 'Updated successfully',
    },
    payments: {
        type: {
            AuthBulkPayments: 'AuthBulkPayments',
            AuthExternalWpsPayments: 'AuthExternalWpsPayments',
            AuthIndividualPayments: 'AuthIndividualPayments',
            AuthWpsPayments: 'AuthWpsPayments',
            ValidateBulkPayment: 'ValidateBulkPayment',
            ExternalValidateWpsPayments: 'ExternalValidateWpsPayments',
            ValidateIndividualPayment: 'ValidateIndividualPayment',
            StatusRepost: 'StatusRepost',
            ViewPayments: 'ViewPayments',
            ValidateWpsPayments: 'ValidateWpsPayments',
            WpsStatus: 'WpsStatus',
        },
    },
}

export default appConstants
